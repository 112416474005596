<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
      <div class="header-label col-lg-4 col-12">Polls List</div>
      <div
        class="
          col-lg-8 col-12
          text-end
          d-flex
          justify-content-end
          custom-flex-cloumn-mob
        "
      ></div>
    </div>
    <div class="custom-ultima-datatable">
      <DataTable
        :value="pollList"
        :scrollable="true"
        scrollHeight="flex"
        :paginator="true"
        :rows="30"
        :lazy="true"
        :rowHover="true"
        :totalRecords="totalRecords"
        @page="changePage($event)"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        :loading="loading"
        dataKey="ah1"
      >
        <template #empty>No Records found.</template>
        <template #loading>Loading data. Please wait...</template>
        <Column
          field="name"
          header="Poll Name"
          headerStyle="width: 25%"
          bodyStyle="width: 25%"
        >
          <template #body="{ data }">
            <div>
              <div class="text-capitalize">
                {{ data.schemelabel ? data.schemelabel : "N/A" }}
              </div>
            </div>
          </template>
        </Column>
        <Column
          field="description"
          header="Template Body"
          headerStyle="width: 60%"
          bodyStyle="width: 60%"
        >
          <template #body="{ data }">
            <div>
              {{ data.schemecontent ? data.schemecontent : "N/A" }}
            </div>
          </template></Column
        >
        <Column
          field="category"
          header="Action"
          headerStyle="width: 15%"
          bodyStyle="width: 15%"
        >
          <template #body="{  }">
            <div>
              <button
                type="button"
                class="btn custom-view-detail-btn mb-2 w-100"
                @click="pollLogsRedirectPage()"
              >
                View Logs
              </button>
              <button
                type="button"
                class="btn custom-view-detail-btn w-100"
                @click="pollReportRedirectPage()"
              >
                View Reports
              </button>
            </div>
          </template></Column
        >
      </DataTable>
    </div>
  </template>
  <script>
  import ApiService from "../../service/ApiService";
//   import router from "@/router";
  export default {
    data() {
      return {
        pollList: [
        {
                    id: 0,
                    schemelabel: "Highend Vehicle Tax Reduced",
                    schemecontent: "The State Cabinet had last month granted its approval to the amendment. According to the new tax slab, the tax on high-end vehicles costing over Rs 1 crore has been capped to a maximum of Rs 15 lakh. Similarly, the tax on high-end motorcycles above Rs 3 lakh has been capped to a maximum of Rs 1.5 lakh. 23 Dec 2023. Are you happy about this decision?",
                    disabledflag: 1,
                },
                {
                    id: 1,
                    schemelabel: "Zuari Bridge Open",
                    schemecontent: "Public Works Department has informed that the completed Left Hand Side 4-Lane Corridor of the New Zuari Bridge on the National Highway, NH-66 will be opened for vehicular traffic from December 24, 2023 at 10.00 a. m. onwards with vehicular transit restrictions on Old Zuari Bridge and traffic movement directions for New Zuari Bridge. Will this reduce your daily commute time?",
                    disabledflag: 1,
                },
        ],
        totalRecords: 2,
        loading: false,
      };
    },
    ApiService: null,
    created() {
      this.ApiService = new ApiService();
    },
    mounted() {
    },
    methods: {
      pollLogsRedirectPage() {
        let route = this.$router.resolve({ path: "/poll/logs" });
      window.open(route.href, '_blank');
      },
      pollReportRedirectPage() {
        let route = this.$router.resolve({ path: "/poll/reports" });
      window.open(route.href, '_blank');
      },
    },
  };
  </script>